import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

import Hr from '../components/hr'
import Card from '../components/card'


const IndexPage = () => (
  <Layout>
    <SEO />
    <section className="pt-4 pb-lg-5">
      <div className="row mt-lg-5 mb-5">
        <div className="col-lg-6 pr-lg-0">
          <Card className="card-yellow">
            <h2 className="card-title card-title-left">Notre mission</h2>
            <p>Les équipes de Fly Me Home aident les patients gravement malades à rejoindre leur pays d’origine, à retrouver leurs proches au moment où cela compte le plus…</p>
          </Card>
        </div>
        <div className="col-lg-6 pl-lg-0">
          <div className="py-lg-5"></div>
          <picture>
            <source srcSet="images/airstair.webp" type="image/webp" />
            <source srcSet="images/airstair.jpg" type="image/jpeg" /> 
            <img className="img-fluid" src="images/airstair.jpg" alt="Un avion Fly7 prêt au décollage." />
          </picture>
        </div>
      </div>
    </section>
    <section className="pb-5">
      <div className="row">
        <div className="col-12">
          <h2 className="mb-4 pb-3">Fly Me Home c'est une rencontre</h2>
        </div>
        <div className="col-md-6">
          <picture>
            <source srcSet="images/portrait.webp" type="image/webp" />
            <source srcSet="images/portrait.jpg" type="image/jpeg" /> 
            <img className="img-fluid mb-4 mb-lg-0" src="images/portrait.jpg" alt="Portrait photgraphique de Nicolas Meylan et Yves Roch" />
          </picture>
        </div>
        <div className="col-md-6 col-lg-5 d-flex flex-column">
          <p>
          Celle de Nicolas Meylan, infirmier chef au CHUV et de Yves Roch, CEO de la compagnie d’aviation FLY7.
          </p>
          <p>
          L’un était confronté depuis longtemps aux demandes des patients et de leurs familles pour réaliser des souhaits de retour à l’étranger dans un contexte de fin de vie.
          </p>
          <p>
          L’autre, bouillonnant entrepreneur, prompt à donner un sens complémentaire et utile à l’aviation privée.
          </p>
          <p>
          C’est pour venir en aide aux autres qu’ils unissent leur savoir-faire.
          </p>
          <Hr className="mt-auto hr-yellow w-25" />
        </div>
      </div>
    </section>
    <section className="py-lg-5">
      <h2 className="mb-4 pb-2">L’action Fly Me Home</h2>
      <div className="row">
        <div className="col-lg-6">
          <ul className="pr-md-3">
            <li>
              Nous organisons toute la logistique du transfert aérien international et médicalisé, c’est le cœur de nos métiers.
            </li>
            <li>
              Nous nous occupons de toutes les autorisations et formalités administratives, pour vous permettre de penser à l’essentiel.
            </li>
          </ul>
        </div>
        <div className="col-lg-6">
          <ul className="pr-md-3">
            <li>
              Nous sélectionnons le personnel médical, choisissons l’équipement  le mieux adapté ainsi que l’équipe de vol.
            </li>
            <li>
              Nous nous chargeons de la collecte de fonds permettant le financement de tout ou partie du transport et des prestations.
            </li>
          </ul>
        </div>
      </div>
    </section>
    <section className="pt-4 pb-lg-5 mb-lg-5">
      <div className="row">
        <div className="col-lg-6 pr-lg-0">
          <Card>
            <h2 className="card-title card-title-right">Accompagner la fin de la vie</h2>

            <p>La fin de vie est un moment où l'on se concentre sur l'essentiel. Cet essentiel varie selon nos origines, nos croyances et notre histoire.
            </p><p>
            Pour certains, il s'agit de se rapprocher de ses racines, d'autres y voient le besoin de revoir des proches.
            </p><p>
            Fly Me Home intervient indépendamment de toutes considérations sociales ou religieuses.</p>
          </Card>
        </div>
        <div className="col-lg-6 pl-lg-0">
          <div className="my-lg-5 py-lg-5 "></div>
          <picture>
            <source srcSet="images/intervention.webp" type="image/webp" />
            <source srcSet="images/intervention.jpg" type="image/jpeg" /> 
            <img className="img-fluid" src="images/intervention.jpg" alt="Transport d'une personne en situation de fin de vie organisé par les équipes du CHUV et de Fly7." />
          </picture>
        </div>
      </div>
    </section>
  </Layout>
)

export default IndexPage
